<template>
    <div class="internal-sections">
        <div class="internal-sections-top">
            <el-row :gutter="20">
                <el-col :span="18">
                    <div class="d-flexk a-i-center">
                        <div class="title-internal-block">
                            Tibbiy Texnikalar qo'shish
                        </div>
                    </div>
                </el-col>
                <el-col :span="6" class="filter_table">
                    <router-link :to="{ name: 'doctorAdd' }">
                        <el-button
                            icon="el-icon-circle-plus-outline"
                            class="asosy-btn-d"
                        >
                            Saqlash
                        </el-button>
                    </router-link>
                </el-col>
            </el-row>
            <el-breadcrumb>
                <el-breadcrumb-item :to="{ path: '/techniqueTable' }">
                    Tibbiy Texnikalar
                </el-breadcrumb-item>
                <el-breadcrumb-item> Texnikalar qo'shish </el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="doctor-add card mt30">
            <div class="card-body p30">
                <div class="steps">
                    <ul class="steps-ul">
                        <li
                            v-for="(item, itemIndex) in steps" :key="'step-'+itemIndex"
                            :class="{ active: item.step === currentStep }"
                            @click="setStep(item.step)"
                        >
                            {{ item.stepText }}
                        </li>
                    </ul>
                </div>

                <div class="steps-body">
                    <div v-if="currentStep === 1" class="step-itme-1">
                        <el-form ref="form" :model="form" class="my-form">
                            <!-- <el-row :gutter="5" class="mb20">
                                <el-col :span="12">
                                    <el-form-item
                                        label="Klinikalar"
                                        class="icons-input"
                                    >
                                        <el-select
                                            v-model="form.region"
                                            placeholder="Murojaat Natijasi"
                                        >
                                            <el-option
                                                label="Rad"
                                                value="shanghai"
                                            ></el-option>
                                            <el-option
                                                label="Berilgan"
                                                value="shanghassi"
                                            ></el-option>
                                            <el-option
                                                label="Va boshqa"
                                                value="beijing"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-button style="margin-top: 29px">
                                        Qo'shish
                                    </el-button>
                                </el-col>
                            </el-row> -->
                        </el-form>
                    </div>

                    <div v-if="currentStep === 2" class="step-itme-2"></div>

                    <div v-if="currentStep === 3" class="step-itme-3"></div>
                </div>

                <div class="step-btn">
                    <el-button
                        class="asosy-btn-y"
                        @click="prevStep"
                        icon="el-icon-back"
                    >
                        Oldingi
                    </el-button>
                    <el-button
                        class="asosy-btn-d"
                        @click="nextStep"
                        type="primary"
                        icon="el-icon-right"
                    >
                        Keyingi
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
export default {
    name: "applicationsAdd",
    data() {
        return {
            currentStep: 1,
            form: {
                type: [],
            },
            dialogImageUrl: "",
            dialogVisible: false,
            disabled: false,
            fileList: [
                {
                    name: "food.jpeg",
                    url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            model: 'doctors/model'
        }),
        steps() {
            return [
                {
                    step: 1,
                    stepText: "Qadam 1",
                },
                {
                    step: 2,
                    stepText: "Qadam 2",
                },
                {
                    step: 3,
                    stepText: "Qadam 3",
                },
            ];
        },
    },
    methods: {
        setStep(step) {
            this.currentStep = step;
        },
        prevStep() {
            if (this.currentStep !== 1) {
                this.currentStep--;
            }
        },
        nextStep() {
            if (this.steps.length > this.currentStep) {
                this.currentStep++;
            }
        },
        handleRemove(file) {
            console.log(file);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleDownload(file) {
            console.log(file);
        },
    },
};
</script>
<style>
</style>
